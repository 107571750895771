import React from 'react';
import { FaArrowCircleRight } from 'react-icons/fa'

import Swiper from '../sub-page-client-swiper/sub-page-client-swiper';

import Styles from './sub-page-client-brief.module.scss';

export default ({ description, images, scope }) => (

  <section className={Styles.briefSection}>
    <div className="container">
      <div className={Styles.briefWrapper}>
        <div className={Styles.briefContainer}>
          <h2 className={Styles.briefTitle}>Brief</h2>
          <p className={Styles.briefText}>{description}</p>
          <h2 className={Styles.briefTitle}>Scope</h2>
          <ul className={Styles.briefList}>
            {scope.map( (current, index) => 
              <li key={index} className={Styles.briefLine}>
                <FaArrowCircleRight /> 
                <p>{current}</p>
              </li> 
            )}
          </ul>
        </div>
        <div className={Styles.swiperContaienr}>
          <Swiper images={images}/>
        </div>
      </div>
    </div>
  </section>
);
