import React from 'react';

import Styles from './sub-page-client-company.module.scss';

export default ( props ) => (

  <section className={Styles.section}>
    <div className="container">

    <div className={Styles.companyContainer}>

      <div className={Styles.company}>
        <div className={Styles.logo}>{props.logo}</div>
      </div>

      <h2 className={Styles.textTitle}>{props.titleDescription}</h2>
      <p className={Styles.text}>{props.description}</p>

      <div className={Styles.techContainer}>
        <h2 className={Styles.techTitle}>Tech</h2>
        <ul className={Styles.techList}>
          {props.tech.map(( value, index ) => 
            <li 
              key={index}
              className={Styles.tech}
            >
              {value}
            </li> 
          )}
        </ul>
      </div>

    </div>

    <div className={Styles.linkWrapper}>
      <div className={Styles.hidden}></div>
      <div className={Styles.buttonContainer}>
        <a 
          href={props.link}
          className="button__content button--pulse"
          target="_blank" 
          rel="noopener noreferrer"
        >
          Visit Site
        </a>
      </div>
    </div>    

    </div>
  </section>
);
