import React from 'react';
import { FaArrowCircleRight } from 'react-icons/fa'

import Styles from './sub-page-client-challenge.module.scss';

export default ({ 
  challenge, solution, solutionPoint, outcome, outcomePoint, image 
}) => (

  <section className={Styles.challengeSection}>
    <div className="container">
      {console.log(outcomePoint)}
      <h2 className={Styles.challengTitle}>Challenge</h2>
      <p className={Styles.challengText}>{challenge}</p>

      <div className={Styles.challengeWrapper}>

        <div className={Styles.challengeImg}>
          {image}
        </div>
        <div className={Styles.textContainer}>
          <h2 className={Styles.solutionTitle}>Solution</h2>
          { solution ? <p className={Styles.solutionText}>{solution}</p> : '' }
          { solutionPoint ? 
            <ul className={Styles.challengeList}>
              { solutionPoint.map( (value, index) => 
                <li key={index} className={Styles.challengeLine}>
                  <FaArrowCircleRight/> 
                  {value}
                </li>
              )}
            </ul> : ''
          }
          <h2 className={Styles.outcomeTitle}>Outcome</h2>
          { outcome ? <p className={Styles.outcomeText}>{outcome}</p> : '' }
          { outcomePoint ? 
            <ul className={Styles.challengeList}>
              { outcomePoint.map( (value, index) => 
                <li key={index} className={Styles.challengeLine}>
                  <FaArrowCircleRight/> 
                  {value}
                </li>
              )}
            </ul> : ''
          }
        </div>
      
      </div>
    </div>
  </section>
);
