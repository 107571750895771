import React from 'react';
import { Link } from 'gatsby';

import Styles from './sub-page-client-see-more.module.scss';

export default ( props ) => (
  
  <section className={Styles.section}>
    <div className="container">
      <h2 className={Styles.title}>Other Clients</h2>
      <div className={Styles.seeMoreContainer}>
        {props.links.map(( value, index ) => 
          <Link 
            key={index} 
            to={`/clients/${value}`}
            className={Styles.link}
          >
            {value}
          </Link>
        )}
      </div>
    </div>
  </section>
);
