import React from 'react';
import Swiper from 'react-id-swiper';
import { Helmet } from 'react-helmet';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

import Styles from './sub-page-client-swiper.module.scss';

export default ({ images }) => {

  const params = {
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    renderPrevButton: () => (
    
      <button 
        className="swiper-button-prev"
        aria-label="Previous"
      >
        <FaChevronLeft />
      </button>
    ),
    renderNextButton: () => (

      <button 
        className="swiper-button-next"
        aria-label="Next"
      >
        <FaChevronRight />
      </button>
    )
  };

  if ( typeof window !== 'undefined' ) {

    return (
  
      <div className={Styles.swiperSection}>
        <Helmet>
          <link 
            rel="stylesheet" 
            href=
              "https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/css/swiper.min.css"
          />
        </Helmet>
          <Swiper {...params}>
            {images.map( ( value, index ) => 
              <div key={index}>{value}</div>
            )}
          </Swiper>
      </div>
    );
  }
  
  else {
    return (<div></div>);
  }
};
