import React from 'react';

/**
 * Images/SVG
 */
import Background from '../../images/inner-page.png';
import JllLogo from '../../images/jll_logo.png';
import Wordpress from '../../images/wordpress-logo.png';
import Css from '../../images/css.png';
import Php from '../../images/php.png';
import Html from '../../images/html.png';
import Js from '../../images/js.png';
import JllImg1 from '../../images/jll1.png';
import JllImg2 from '../../images/jll2.png';

/**
 * Components
 */
import SEO from '../../components/seo/seo';
import Layout from '../../components/layout/layout';
import DefaultPagesBanner from '../../components/default-banner/default-banner';
import Company from 
  '../../components/sub-page-client-company/sub-page-client-company';
// import Testimonial from 
//   '../../components/sub-page-client-testimonial/sub-page-client-testimonial';
import SeeMore from 
  '../../components/sub-page-client-see-more/sub-page-client-see-more';
import Brief from 
  '../../components/sub-page-client-brief/sub-page-client-brief';
import Challenge from 
  '../../components/sub-page-client-challenge/sub-page-client-challenge';

export default () => {

  /**
   * Banner
   */
  const innerBannerData = {
    backgroundImg: { backgroundImage: `url(${Background}` },
    mainTitle: 'JLL',
    mainText: 'Crown Estate’s Site'
  };

  /**
   * Company data
   */
  const descriptionData = {
    link: 'https://www.myregentstreet.com/',
    logo: <img alt="jll logo" src={JllLogo}/>,
    titleDescription: 'JLL – Creating a unique service standout in the real estate market',
    description: 
      'We are the company responsible for the property management of The Crown Estate’s central London holding, including its Regent Street portfolio.',
    tech:[
      <img alt="wordpress logo" src={Wordpress} />,
      <img alt="css logo" src={Css}/>,
      <img alt="js logo" src={Js}/>,
      <img alt="html logo" src={Html}/>,
      <img alt="php logo" src={Php}/>
    ]
  };

  /**
   * Brief Data
   */
  const briefData = {
    description: 
      'To deliver a captivating website for the world’s most inspirational destination where people come to shop, work, live and meet, so we scheduled a strategy session which gave us room to clarify an objective roadmap and quickly see what strategy will help enhance the brand.',
    images: [
      <img alt="jll site" src={JllImg1} />,
    ],
    scope: [
      'Design a fully responsive website', 
      `Tenant's online platform`
    ]
  };

  /**
   * Challenge Data
   */
  const challengeData = {
    challenge: 
      'To make the tenant’s online platform automated and include some features such as onboarding or reception service.',
    solution: false,
    solutionPoint: [
      'Client blueprint',
      'Design',
      'Development',
      'After project support'
    ],
    outcome: 
      'The new JLL website is fresh, user-friendly and grabs attentions instantly. The new site structure and functionalities facilitate easy renting and bespoke customer support.',
    outcomePoint: false,
    image: <img alt="jll site" src={JllImg2} />
  };

  /**
   * Testemonial data
   */
  // const testimonialData = {
  //   quote: 
  //     'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
  //   name: 'Fred',
  //   position: 'full-stack'
  // };

  /**
   * See more footer
   */
  const seeMoreData = {
    links: ['cps','pro','usimoney']
  };

  return (
    <Layout>
      <SEO 
        title="Clients" 
        keywords={
          [`digital`, `agency`, `technology`, `Radium`, `jjl`, `Client`]
        } 
      />
      <DefaultPagesBanner {...innerBannerData} />
      <Company {...descriptionData} />
      <Brief {...briefData} />
      <Challenge {...challengeData}/>
      <SeeMore {...seeMoreData} />
    </Layout>
  )
};
